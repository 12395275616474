import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const ContentWrapper = styled.div`
  max-width: 1300px;
  margin: 0 auto;
`;

const Wrapper = styled.div`
  top: -30px;
  z-index: 11;
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  position: relative;

  @media (min-width: 768px) {
    padding-left: 70px;
    padding-right: 70px;
    /* top: -30px; */
    /* top: 0; */
    /* transform: translateY(-50%); */
  }

  @media (min-width: 992px) {
    padding-left: 40px;
    padding-right: 40px;
    /* top: -30px; */
    /* top: 0; */
    /* transform: translateY(-50%); */
  }

  @media (min-width: 1200px) {
    padding-left: 70px;
    padding-right: 70px;
  }
`;

const Grid = styled.div`
  margin-top: -30px;
  z-index: 11;
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: 768px) {
    position: absolute;
    margin-top: 0;
    top: -30px;
    width: calc(100% - 140px);
    grid-template-columns: repeat(5, 1fr);
  }

  @media (min-width: 992px) {
    width: calc(100% - 80px);
  }

  @media (min-width: 1200px) {
    width: calc(100% - 140px);
  }
`;

const Label = styled.span`
  display: block;
  text-transform: uppercase;
  color: ${({theme}) => theme.colors.secondary};
  margin-bottom: 0.25rem;
  font-weight: 400;

  @media (min-width: 992px) {
    font-size: 0.75rem;
  }

  @media (min-width: 1200px) {
    font-size: 0.875rem;
  }

  @media (min-width: 1400px) {
    font-size: 1rem;
  }
`;

const Value = styled.span`
  display: block;
  color: ${({theme}) => theme.colors.white};
  font-size: 1.25rem;
  font-weight: 600;

  @media (min-width: 1200px) {
    font-size: 1.375rem;
  }
`;

const ItemContent = styled.div`
  padding: 0 1rem 0.5rem 1rem;

  @media (min-width: 992px) {
    padding: 0.5rem 1rem;
  }
`;

const Item = styled.div`
  /* background-color: ${({theme}) => theme.colors.primary}; */
  background-color: #eaa548;
  color: ${({theme}) => theme.colors.white};
  text-align: center;
  padding: 1rem 1rem 0;

  ${Value} {
    /* margin-bottom: 0.5rem; */
  }

  &:first-child,
  &:nth-child(2),
  &:nth-child(3) {
    ${ItemContent} {
      border-bottom: 2px solid ${({theme}) => theme.colors.secondary};
    }
  }

  &:nth-child(4) {
    ${ItemContent} {
      border-bottom: 2px solid ${({theme}) => theme.colors.secondary};
    }
  }

  /* &:last-child {
    background-color: ${({theme}) => theme.colors.secondary};
    color: ${({theme}) => theme.colors.primary};

    ${Label}, ${Value} {
      color: ${({theme}) => theme.colors.primary};
    }
  } */

  @media (min-width: 768px) {
    padding: 1rem 0;

    &:first-child,
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      ${ItemContent} {
        border-bottom: 0;
      }
    }

    &:first-child {
      ${ItemContent} {
        border-right: 1px solid ${({theme}) => theme.colors.secondary};
      }
    }

    &:nth-child(2),
    &:nth-child(3) {
      ${ItemContent} {
        border-left: 1px solid ${({theme}) => theme.colors.secondary};
        border-right: 1px solid ${({theme}) => theme.colors.secondary};
      }
    }

    &:nth-child(4) {
      ${ItemContent} {
        border-left: 1px solid ${({theme}) => theme.colors.secondary};
        border-right: 1px solid ${({theme}) => theme.colors.secondary};
      }
    }

    &:last-child {
      ${ItemContent} {
        border-left: 1px solid ${({theme}) => theme.colors.secondary};
      }
    }
  }
`;

function InfoPanel({data: {type, floor, balcony, terrace, garden, price}, sum}) {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <ContentWrapper>
        <Grid>
          <Item>
            <ItemContent>
              <Label>{t('detail.info.type')}</Label>
              <Value>{type}</Value>
            </ItemContent>
          </Item>
          <Item>
            <ItemContent>
              <Label>{t('detail.info.floor')}</Label>
              <Value>{t('detail.info.floor_number', {number: floor})}</Value>
            </ItemContent>
          </Item>
          <Item>
            <ItemContent>
              <Label>{t('detail.info.area')}</Label>
              <Value>{new Intl.NumberFormat('cs-CZ').format(sum)}&nbsp;m<sup>2</sup></Value>
            </ItemContent>
          </Item>
          <Item>
            <ItemContent>
              <Label>{garden ? t('detail.info.garden') : (terrace ? t('detail.info.terrace') : t('detail.info.balcony'))}</Label>
              <Value>{garden ? new Intl.NumberFormat('cs-CZ').format(garden.value) : (terrace ? new Intl.NumberFormat('cs-CZ').format(terrace.value) : new Intl.NumberFormat('cs-CZ').format(balcony.value))}&nbsp;m<sup>2</sup></Value>
            </ItemContent>
          </Item>
          <Item>
            <ItemContent>
                <Label>{t('detail.info.price')}</Label>
                <Value>{price > 0 ? new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: 'CZK', minimumFractionDigits: 0 }).format(Math.round(price)) : '---'}</Value>
              </ItemContent>
            </Item>
        </Grid>
      </ContentWrapper>
    </Wrapper>
  );
}

export default InfoPanel;