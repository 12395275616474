import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { StaticImage } from "gatsby-plugin-image";
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import styled from 'styled-components';
import { Element, scroller } from 'react-scroll';
import Layout from '../components/layout';
import LangSelector from '../components/LangSelector';
import InfoPanel from '../components/ApartmentDetail/infoPanel';
import ContactForm from '../components/ContactForm';
import bgShadowSrc from '../images/bg-shadow.jpg';
import Seo from '../components/seo';
import { Title } from '../components/common/topPanel';
import {
  GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3';
import Lightbox from 'react-image-lightbox';
import { RECAPTCHA_SITE_KEY } from "../config";
import 'react-image-lightbox/style.css';

const Content = styled.div`
  background-color: #fff;
  background-image: url(${bgShadowSrc});
  background-repeat: repeat-y;
  background-position: top left;
`;

const TopPanel = styled.div`
  position: relative;
  height: 350px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  padding: 1rem;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #303030;
    opacity: 0.3;
  }

  @media(min-width: 768px) {
    height: 400px;
  }

  .panel-image {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
`;

const ContentWrapper = styled.div`
  max-width: 1300px;
  margin: 0 auto;
`;

const Section = styled.div`
  background-color: #fff;
  background-image: url(${bgShadowSrc});
  background-repeat: repeat-y;
  background-position: top left;
  padding: 40px 1.5rem 70px;
  position: relative;

  @media(min-width: 768px) {
    padding: 70px;
  }

  @media (min-width: 992px) {
    padding: 70px 40px;
  }

  @media (min-width: 1200px) {
    padding: 70px;
  }
`;

const DarkSection = styled.div`
   padding: 70px 1.5rem;
  position: relative;
  background-color: #434242;

  @media(min-width: 768px) {
    padding: 70px;
  }

  @media (min-width: 992px) {
    padding: 70px 40px;
  }

  @media (min-width: 1200px) {
    padding: 70px;
  }
`;

const Subtitle = styled.p`
  display: flex;
  align-items: center;
  /* font-family: 'Playfair Display', Georgia, sans-serif; */
  font-weight: 600;
  font-size: 1.25rem;
  text-align: left;
  color: #303030;
  margin-bottom: 2rem;
  text-transform: uppercase;
  /* position: relative; */

  /* span {
    display: inline-block;
    position: relative;
    background-color: #fff;
    z-index: 2;
    padding-right: 1rem;
  } */

  &:after {
    /* content: '';
    position: absolute;
    top: 15px;
    right: 0;
    width: 100%;
    height: 1px;
    z-index: 0; */
    content: '';
    flex: 1;
    margin-left: 1rem;
    height: 1px;
    background-color: #ff940a;
    /* transform: translate(-50%,-50%); */
  }
`;

const Square = styled.span`
  width: 20px;
  height: 20px;
  margin-right: 20px;
  background-color: ${({bgColor}) => bgColor};
  box-shadow: -1px 3px 7px rgba(0, 0, 0, 0.72);
`;

const LegendItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-transform: uppercase;
  color: #fefefe;
  z-index: 2;
  font-size: 1.25rem;
  text-shadow: -1px 3px 7px rgba(0, 0, 0, 0.72);
`;

const LegendGrid = styled.div`
  /* margin-top: 40px; */
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 2rem;
  row-gap: 2rem;

  @media(min-width: 768px) {
    margin-top: 30px;
    grid-template-columns: 1fr 1fr;
  }

  @media(min-width: 992px) {
    margin-top: 100px;
    grid-template-columns: 1fr 1fr;
  }
`;

const Img = styled.img`
  width: 100%;
  height: auto;
`;

const ClickableImg = styled(Img)`
  cursor: pointer;
`;

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: 26px minmax(150px, max-content) max-content;
  column-gap: 1rem;
  row-gap: 0.5rem;
  align-items: center;
`;

const Value = styled.span`
  font-weight: 600;
  text-align: right;
`;

const SumLabel = styled.span`
  font-weight: 700;
  grid-column: span 2;

  small {
    display: block;
    font-size: 0.875rem;
    font-weight: 400;
  }
`;

const Divider = styled.div`
  height: 16px;
  grid-column: span 3;
`;

const SumValue = styled.span`
  font-weight: 700;
  text-align: right;
  align-self: flex-start;
`;

const Circle = styled.span`
  background-color: #ff940a;
  border-radius: 50%;
  color: #fff;
  width: 26px;
  height: 26px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ImagesWrapper = styled.div`
  background-color: #f4f5f5;
  padding: 70px 1.5rem;

  @media(min-width: 768px) {
    padding: 70px;
  }
`;

const Images = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 3rem;
  row-gap: 3rem;

  @media(min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const AdditionalInfo = styled.div`
  margin-top: 1rem;
  p {
    text-decoration: underline;
  }
`;

const ContactTitle = styled.p`
  text-transform: uppercase;
  color: #ff940a;
  font-size: 1.25rem;
  margin-bottom: 2rem !important;
`;

const ContactWrapper = styled.div`
  text-align: center;
  color: #fefefe;

  a, a:hover {
    color: #fefefe;
    text-decoration: none;
  }

  p {
    margin-bottom: 0.5rem;
  }

  p:last-child {
    margin-bottom: 0;
  }
`;

const ContactGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 2rem;
  row-gap: 2rem;

  @media(min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const Name = styled.span`
  display: block;
  font-weight: 600;
  font-size: 1.125rem;
  margin-bottom: 1rem;
`;

const FormWrapper = styled.div`
  p {
    color: #fefefe;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 1.5rem;
  z-index: 2;

  button {
    font-size: 1rem;
    text-transform: uppercase;
    border: 0;
    background-color: #ff940a;
    /* background-color: ${({theme}) => theme.colors.secondary}; */
    /* color: ${({theme}) => theme.colors.primary}; */
    color: #fefefe;
    padding: 0.75rem;
    width: 100%;
    box-shadow: -1px 3px 7px rgba(0, 0, 0, 0.72);
    cursor: pointer;
    min-width: 180px;

    &:focus {
      border: 0;
      outline: 0;
    }

    &:hover {
      background-color: #303030;
    }
  }
`;

const LinkWrapper = styled.div`
  margin-top: 1.5rem;

  a {
    display: inline-block;
    font-size: 1rem;
    text-transform: uppercase;
    border: 0;
    background-color: #ff940a;
    color: #fefefe;
    padding: 0.75rem;
    cursor: pointer;
    min-width: 220px;
    text-decoration: none;
    text-align: center;


    &:focus {
      border: 0;
      outline: 0;
    }

    &:hover {
      text-decoration: none;
      background-color: #303030;
      color: #fefefe;
    }
  }
`;

const InfoWrapper = styled.div`
  @media (min-width: 768px) {
    order: 2;
  }
`;

const PlanWrapper = styled.div`
  @media (min-width: 768px) {
    order: 1;
  }
`;

function Detail ({pageContext }) {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const [others, setOthers] = useState([]);
  const [sum, setSum] = useState(0);

  const getFillColor = state => {

    if(state === 'sold') {
      return '#e6e6e6';
    }

    if(state === 'reserved') {
      return '#666666';
    }

    return '#ff940a';
  }

  useEffect(() => {
    window.scrollTo(0,0);
    const arr = [];

    if(pageContext.balcony) {
      arr.push({label: 'balcony', ...pageContext.balcony});
    }
    if(pageContext.terrace) {
      arr.push({label: 'terrace', ...pageContext.terrace});
    };
    setOthers(arr.sort((a, b) => a.id - b.id));

    const balcony = pageContext.balcony?.value || 0;
    const terrace = pageContext.terrace?.value || 0;

    setSum(pageContext.sum_area+balcony+terrace+pageContext.cellar);

  },[pageContext]);



  return (
    <Layout>
      <Seo title={t('seo.house_detail.title', {id: pageContext.id})} />
      { isOpen && (
        <Lightbox
          reactModalProps={{ shouldReturnFocusAfterClose: false }}
          enableZoom={false}
          closeLabel={t('gallery.close')}
          prevLabel={t('gallery.prev')}
          nextLabel={t('gallery.next')}
          mainSrc={`/data/${pageContext.building.toLowerCase()}/${pageContext.id.toLowerCase()}_pudorys.jpg`}
          onCloseRequest={e => { setOpen(false);
          }}
        />
      )}
      <Content>
        <TopPanel>
          <StaticImage src="../images/img-bg.jpg" className="panel-image" placeholder="blurred" alt={t('photo_alt')} />
          <LangSelector />
          <Title as="h1">{t('detail.title', {id: pageContext.id})}</Title>
          <LegendItem>
            <Square bgColor={getFillColor(pageContext.state)} />
            <span>{t(`state.${pageContext.state}`)}</span>
          </LegendItem>
          {
            pageContext.state === 'available' && (
              <ButtonWrapper>
                <button onClick={() => {
                  scroller.scrollTo('contact-element', {
                    duration: 500,
                    delay: 0,
                    smooth: 'linear',
                    offset: -150,
                  })
                }}>{t('detail.btn_interest')}</button>
              </ButtonWrapper>
            )
          }
        </TopPanel>
        <InfoPanel data={pageContext} sum={sum} />
        <Section>
          <ContentWrapper>
            <LegendGrid>
              <InfoWrapper>
                <Subtitle as="h2">{t('detail.title_area')}</Subtitle>
                <InfoGrid>
                  {
                    pageContext.rooms.sort((a, b) => a.id - b.id).map(item => (
                      <React.Fragment key={item.id}>
                        <Circle>{item.id}</Circle>
                        <span>{t(`detail.rooms.${item.label}`)}</span>
                        <Value>{new Intl.NumberFormat('cs-CZ').format(item.value)}&nbsp;m<sup>2</sup></Value>
                      </React.Fragment>
                    ))
                  }
                  <span></span>
                  <span>{t('detail.partition')}</span>
                  <Value>{new Intl.NumberFormat('cs-CZ').format(pageContext.sum_area-pageContext.area)}&nbsp;m<sup>2</sup></Value>
                  <Divider />
                  <SumLabel>
                    <Trans i18nKey="detail.area_note">
                      message <small>note</small>
                    </Trans>
                  </SumLabel>
                  <SumValue>{new Intl.NumberFormat('cs-CZ').format(pageContext.sum_area)}&nbsp;m<sup>2</sup></SumValue>
                  <Divider />
                  {
                    others.map(item => (
                      <React.Fragment key={item.id}>
                        <Circle>{item.id}</Circle>
                        <span>{t(`detail.${item.label}`)}</span>
                        <Value>{new Intl.NumberFormat('cs-CZ').format(item.value)}&nbsp;m<sup>2</sup></Value>
                      </React.Fragment>
                    ))
                  }
                  <span></span>
                  <span>{t(`detail.rooms.cellar`)}</span>
                  <Value>{new Intl.NumberFormat('cs-CZ').format(pageContext.cellar)}&nbsp;m<sup>2</sup></Value>
                  <Divider />
                  <SumLabel>{t('detail.sum_area')}</SumLabel>
                  <SumValue>{new Intl.NumberFormat('cs-CZ').format(sum)}&nbsp;m<sup>2</sup></SumValue>

                  {
                    pageContext.garden && (
                      <>
                        <Divider />
                        <Circle>{pageContext.garden.id}</Circle>
                        <span>{t(`detail.rooms.garden`)}</span>
                        <Value>{new Intl.NumberFormat('cs-CZ').format(pageContext.garden.value)}&nbsp;m<sup>2</sup></Value>
                      </>
                    )
                  }
                </InfoGrid>

                <AdditionalInfo>
                  <p>{t('detail.items_included')}</p>
                  <ul>
                    {
                      pageContext.garden && (
                        <li>{t('detail.garden')}</li>
                      )
                    }
                    <li>{t('detail.cellar')}</li>
                    <li>{t('detail.parking')}</li>
                  </ul>
                </AdditionalInfo>
                <LinkWrapper>
                 {pageContext.state === 'available' && (
                   <a href={`/data/pdf/${pageContext.id}.pdf`} target="_blank" rel="noreferrer">
                    {t('detail.detail_card')}
                  </a>
                 )}

                </LinkWrapper>
              </InfoWrapper>
              <PlanWrapper>
                <ClickableImg
                  src={`/data/${pageContext.building.toLowerCase()}/${pageContext.id.toLowerCase()}_pudorys.jpg`}
                  alt={t('detail.photo_floor_plan_alt')}
                  onClick={() => setOpen(true)} />
              </PlanWrapper>
            </LegendGrid>
          </ContentWrapper>
        </Section>
        <ImagesWrapper>
          <ContentWrapper>
            <Images>
              <Img src={`/data/${pageContext.building.toLowerCase()}/dum${pageContext.building}_umisteni.jpg`} alt={t('detail.photo_location_alt')} />
              <Img src={`/data/${pageContext.building.toLowerCase()}/${pageContext.id.toLowerCase()}_situace.png`} alt={t('detail.photo_layout_alt')} />
            </Images>
          </ContentWrapper>
        </ImagesWrapper>
        <DarkSection>
          <Element name="contact-element">
            <ContentWrapper>
              <ContactGrid>
                    <ContactWrapper>
                      <ContactTitle as="h2">{t('detail.contact')}</ContactTitle>
                      <Name>REAL SPEKTRUM, a.s.</Name>
                      <p>
                        <a href="mailto:rs@realspektrum.cz">rs@realspektrum.cz</a>
                      </p>
                      <p>
                      <a href="tel:+420800800099">
                        +420&nbsp;800&nbsp;800&nbsp;099
                      </a>
                      </p>
                    </ContactWrapper>
                    <FormWrapper>
                      <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY} language="cs">
                        <p>{t('detail.contact_message')}</p>
                        <ContactForm elementId="detail-form" />
                      </GoogleReCaptchaProvider>
                    </FormWrapper>
              </ContactGrid>
            </ContentWrapper>
          </Element>
        </DarkSection>
      </Content>
    </Layout>
  )
}

export default Detail;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
