import styled from 'styled-components';

export const TopPanel = styled.div`
  position: relative;
  height: 350px;
  width: 100%;
  /* background-image: url(${({bgSrc}) => bgSrc});
  background-repeat: no-repeat;
  background-position: top right;
  background-size: cover; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  padding: 1rem;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #303030;
    opacity: 0.3;
  }

  .panel-image {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  @media(min-width: 992px) {
    height: 600px;
  }
`;

export const Title = styled.p`
  font-family: 'Playfair Display', Georgia, sans-serif;
  font-weight: 700;
  color: #fefefe;
  text-align: center;
  font-size: 2rem;
  line-height: 2.5rem;
  max-width: 750px;
  text-shadow: -1px 3px 7px rgba(0, 0, 0, 0.72);
  z-index: 2;
  word-break: break-word;

  @media(min-width: 768px) {
    font-size: 3.75rem;
    line-height: 4.5rem;
  }
`;
